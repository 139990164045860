export default (handle = null, data = {}, type = 'slideout') => ({
    open: function($dispatch, previousFocusSelector) {
        if (handle) {
            htmx.find('#modal-handle').value = handle;
            htmx.find('#modal-data').value = JSON.stringify(data);
            htmx.trigger(htmx.find('#modal-body'), 'refresh');
            $dispatch('openmodal', type);
            if (previousFocusSelector) {
                window.previousFocus = previousFocusSelector;
            }
        }
    }
});